@import '../../../../styles/vars';
@import '../../../../styles/keyframes.scss';

.theme {
  padding: 4rem 4rem 0;
  background: #f8f8f8;
  border-radius: 0.5rem;
  height: 38.75rem;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  animation-duration: 0.5s;
  opacity: 0;
  animation-fill-mode: forwards;

  &--animate {
    animation-name: fadeInUp, fadeIn;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1), cubic-bezier(0, 0, 0.2, 1);
  }
}

.theme + .theme {
  margin-top: 56px;
}

.section__left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 42.13%;
  padding: 0 3.5625rem 4rem 0;
}

.logo__wrapper {
  width: 3.5rem;
  height: 3.5rem;
  img {
    aspect-ratio: 1 / 1;
    position: relative !important;
  }
}

.heading {
  color: $dark;
  padding-bottom: 0.75rem;
  display: block;
}

.excerpt {
  max-width: 600px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.button {
  svg {
    transition: transform 0.4s ease-in-out;
    display: block;
  }
  &:hover {
    svg {
      transform: rotate(45deg);
    }
  }
}

.theme__right {
  display: block;
  width: 57.87%;
  position: relative;
}

.theme__mobile {
  right: 0;
  width: 25.55%;
  position: absolute;
  bottom: -13.7%;
  z-index: 5;
  img {
    aspect-ratio: 246 / 533;
    position: relative !important;
    border-radius: 12px;
    border: 7px solid #fff;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.03);
  }
}

.theme__desktop {
  left: 0;
  top: 0;
  width: 89.83%;
  position: absolute;
  z-index: 0;
  img {
    aspect-ratio: 865 / 655;
    position: relative !important;
    border-radius: 4px 4px 0 0;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.03);
  }
}

@media (max-width: 1700px) {
  .theme {
    padding: 4rem 2rem 0 4rem;
    height: 34rem;
  }
}

@media (max-width: 1480px) {
  .theme {
    height: 30rem;
    padding: 3rem 3rem 0;
  }

  .theme + .theme {
    margin-top: 3rem;
  }

  .section__left {
    padding: 0 3rem 2rem 0;
  }

  .excerpt {
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 1280px) {
  .theme {
    height: 27rem;
  }

  .section__left {
    width: 45%;
  }

  .theme__right {
    width: 55%;
  }
}

@media (max-width: 1200px) {
  .theme {
    padding: 2.5rem 2.5rem 0;
    height: 33vw;
  }

  .theme + .theme {
    margin-top: 2.5rem;
  }

  .section__left {
    padding: 0 1.5rem 2.5rem 0;
    width: 50%;
  }

  .theme__right {
    width: 50%;
  }

  .heading {
    padding-bottom: 0.75rem;
  }
}

@media (max-width: 1024px) {
  .theme {
    height: 22rem;
    padding: 2rem 2rem 0;
  }

  .theme + .theme {
    margin-top: 2rem;
  }

  .buttons {
    flex-wrap: wrap;
  }

  .section__left {
    padding: 0 1rem 2rem 0;
  }

  .theme__desktop {
    bottom: 0;
    top: auto;
  }

  .theme__mobile {
    bottom: -5%;
    img {
      border-width: 4px;
    }
  }

  .excerpt {
    padding-bottom: 1.25rem;
  }
}

@media (max-width: 950px) {
  .theme {
    height: auto;
    flex-direction: column;
  }

  .section__left {
    height: auto;
    width: 79%;
    padding-bottom: 3rem;
  }

  .section__info {
    padding-top: 2rem;
  }

  .theme__right {
    width: 100%;
    flex-grow: 1;
    height: 45vw;
  }

  .theme__desktop {
    top: 0;
    width: 75%;
    left: 3rem;
    bottom: auto;
  }

  .theme__mobile {
    width: 20%;
    right: 3rem;
    bottom: -15%;
  }
}

@media (max-width: 768px) {
  .section__left {
    width: 100%;
  }

  .theme__right {
    height: 40vw;
  }

  .theme__mobile {
    bottom: -10%;
  }
}

@media (max-width: 640px) {
  .theme__right {
    height: 38vw;
  }

  .theme__desktop {
    left: 2rem;
  }

  .theme__mobile {
    right: 2rem;
  }
}

@media (max-width: 576px) {
  .theme {
    padding: 1.5rem 1.5rem 0;
  }

  .theme + .theme {
    margin-top: 1.5rem;
  }

  .section__left {
    padding: 0 1.5rem 3rem 0;
  }

  .section__info {
    padding-top: 2rem;
  }

  .theme__desktop {
    left: 1rem;
    width: 80%;
  }

  .theme__right {
    height: 45vw;
  }

  .theme__mobile {
    right: 1rem;
    width: 25%;
    bottom: -20%;
  }
}

@media (max-width: 480px) {
  .section__left {
    padding: 0 0 2.5rem 0;
  }

  .heading {
    padding-bottom: 0.5rem;
  }

  .buttons {
    flex-direction: column;
    align-items: flex-start;
    a,
    button {
      width: 100%;
    }
  }

  .theme__desktop {
    left: 0;
    width: 85%;
    bottom: auto;
    top: 0;
  }

  .theme__mobile {
    right: 0;
    width: 30%;
    bottom: -28%;
  }
}

@media (max-width: 414px) {
  .section__left {
    padding: 0 0 2rem 0;
  }

  .theme__mobile {
    img {
      border-radius: 0.375rem;
      border: 3px solid $white;
    }
  }
}

@media (max-width: 376px) {
  .theme {
    padding: 1.25rem 1.25rem 0;
  }

  .section__left {
    padding: 0 0 1.5rem 0;
  }

  .theme__desktop {
    width: 95%;
  }

  .theme__mobile {
    width: 30%;
    bottom: -20%;
  }

  .theme__right {
    height: 53vw;
  }
}
