@import '../../../../styles/vars';
@import '../../../../styles/keyframes.scss';

.hero {
  height: 800px;
  background: $dark;
  // position: relative;
  overflow: hidden;
  display: flex;
}

.hero__left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 120px;
  max-width: calc(4rem + 810px + 5rem);
  padding: 0 5rem 7.5rem 4rem;
  flex-shrink: 0;
}

.heading {
  text-align: left;
  color: $white;
  padding-bottom: 0.25rem;
  line-height: 72%;

  .word__wrapper {
    line-height: 80%;

    span {
      transform: translateY(100%);
      line-height: 114%;
      vertical-align: middle;
    }
  }

  span {
    display: inline-block;
  }
}

.word__wrapper {
  overflow-y: hidden;
  overflow-x: auto;
}

.animate__heading {
  .word__wrapper span {
    animation: $headingFade;
  }
}

.animate__excerpt {
  line-height: 100%;

  .word__wrapper span {
    animation: $excerptFade;
  }
}

.excerpt {
  max-width: 600px;
  line-height: 73%;
  color: $white;
  opacity: 0.7;

  .word__wrapper {
    line-height: 100%;

    span {
      transform: translateY(100%);
      line-height: 140%;
    }
  }

  span {
    display: inline-block;
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  opacity: 0;
  animation: fadeIn 0.6s ease-in-out 1s forwards;
}

.background.slide {
  height: 16.93vw;
  width: 33.33vw;

  img {
    position: relative !important;
    aspect-ratio: 640 / 325;
    border-radius: 4px;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
  }
}

.hero__right {
  display: flex;
  gap: 1.25rem;
  height: auto;
  width: auto;

  opacity: 0;
  animation: fadeIn 0.7s ease-in-out 1s forwards;
}

.swiper {
  // top: 0;
  flex-shrink: 0;

  // margin: 0;
  .swiper-wrapper {
    height: calc(5000 * (16.93vw * 10 + 24px * 9));
  }
}

@media (min-width: 3560px) {
  .background.slide {
    height: 508.8125px;
    width: 1000px;
  }

  .hero__left {
    margin-left: calc((100vw - 1792px) / 2);
    padding: 0 5rem 7.5rem 0;
    max-width: calc(810px + 5rem);
  }

  .swiper {
    position: relative !important;
    // top: 0;
    flex-shrink: 0;

    // margin: 0;
    .swiper-wrapper {
      height: calc(5000 * (508.8125px * 10 + 16px * 9));
    }
  }
}

@media (min-width: 2560px) {
  .background.slide {
    height: 406.25px;
    width: 800px;
  }

  .hero__left {
    margin-left: calc((100vw - 1792px) / 2);
    padding: 0 5rem 7.5rem 0;
    max-width: calc(810px + 5rem);
  }

  .swiper {
    position: relative !important;
    // top: 0;
    flex-shrink: 0;

    // margin: 0;
    .swiper-wrapper {
      height: calc(5000 * (406.25px * 10 + 16px * 9));
    }
  }
}

@media (min-width: 1921px) {
  .background.slide {
    height: 325px;
    width: 640px;
  }

  .hero__left {
    margin-left: calc((100vw - 1792px) / 2);
    padding: 0 5rem 7.5rem 0;
    max-width: calc(810px + 5rem);
  }

  .swiper {
    position: relative !important;
    // top: 0;
    flex-shrink: 0;

    // margin: 0;
    .swiper-wrapper {
      height: calc(5000 * (325px * 10 + 16px * 9));
    }
  }
}

@media (max-width: 1700px) {
  .hero__left {
    max-width: calc(2 * 4rem + 730px);
  }
}

@media (max-width: 1480px) {
  .hero {
    height: 41rem;
  }

  .hero__left {
    padding: 0 2rem 6.5rem 3rem;
    max-width: calc(2rem + 3rem + 640px);
  }

  .hero__right {
    gap: 1rem;
  }
}

@media (max-width: 1280px) {
  .hero__left {
    max-width: calc(2rem + 3rem + 600px);
    padding: 0 2rem 4rem 3rem;
  }
}

@media (max-width: 1200px) {
  .hero {
    height: 37rem;
  }

  .hero__left {
    padding: 0 2rem 4rem 2.5rem;
  }
}

@media (max-width: 1024px) {
  .hero {
    height: 34rem;
  }

  .hero__left {
    max-width: 100%;
    width: 55%;
    padding: 0 2rem 4rem 2rem;
  }

  .background.slide {
    height: 15vw;
    width: 29.54vw;

    &.test {
      border-radius: 100px !important;
      overflow: hidden !important;
    }
  }

  .swiper {
    position: relative !important;
    top: 0;
    flex-shrink: 0;
    margin: 0;

    .swiper-wrapper {
      height: calc(5000 * (15vw * 10 + 24px * 9));
    }
  }
}

@media (max-width: 820px) {
  .hero {
    height: auto;
    flex-direction: column;
    padding-bottom: 3rem;
  }

  .hero__left {
    width: 79%;
    justify-content: flex-start;
    height: auto;
    padding-top: 5rem;
    padding-bottom: 3rem;
  }

  .hero__right {
    flex-direction: column;
    height: 244px;
  }

  .swiper {
    height: 100%;

    .swiper-wrapper {
      height: auto;
      width: auto;
    }
  }

  .background.slide {
    width: auto;
    height: 100%;
  }

  .second__swiper {
    display: none;
  }
}

@media (max-width: 768px) {
  .hero__right {
    height: 204px;
  }
}

@media (max-width: 640px) {
  .hero__left {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .hero__left {
    padding: 5rem 1.5rem 3rem;
    height: auto;
  }

  .hero__right {
    height: 183px;
  }

  .buttons {
    flex-wrap: wrap;
  }

  .section__container {
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 480px) {
  .hero {
    padding-bottom: 2rem;
  }

  .hero__left {
    padding: 4rem 1.5rem 2rem;
    height: auto;
  }

  .hero__right {
    height: 163px;
  }

  .buttons {

    a,
    button {
      width: 100%;
    }
  }

  .heading {
    padding-bottom: 0.5rem;
  }
}

@media (max-width: 414px) {
  .hero {
    padding-bottom: 1.5rem;
  }

  .hero__left {
    padding: 4rem 1.25rem 1.5rem;
  }
}

@media (max-width: 376px) {
  .hero__right {
    height: 142px;
  }
}