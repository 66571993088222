@import '../../../../styles/vars';
@import '../../../../styles/keyframes.scss';

.advantage__section {
  background: #f8f8f8;
  padding: 7.5rem 0;
}

.heading {
  text-align: left;
  color: $dark;
  padding-bottom: 3.5rem;
  line-height: 72%;
  .word__wrapper {
    line-height: 80%;
    span {
      transform: translateY(100%);
      line-height: 114%;
      vertical-align: middle;
    }
  }

  span {
    display: inline-block;
  }
}

.word__wrapper {
  overflow-y: hidden;
  overflow-x: auto;
}

.animate__heading {
  .word__wrapper span {
    animation: $headingFade;
  }
}

@media (max-width: 1480px) {
  .heading {
    padding-bottom: 3rem;
  }

  .advantage__section {
    padding: 6rem 0;
  }
}

@media (max-width: 1200px) {
  .advantage__section {
    padding: 5rem 0;
  }

  .heading {
    padding-bottom: 2.5rem;
  }
}

@media (max-width: 768px) {
  .advantage__section {
    padding: 4rem 0;
  }
}

@media (max-width: 576px) {
  .advantage__section {
    padding: 3rem 0;
  }

  .heading {
    padding-bottom: 2rem;
  }
}
