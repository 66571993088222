@import '../../../../styles/vars';

.label {
  display: inline-block;
  width: fit-content;
  color: #5f5f5f;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
