@import '../../../../styles/vars';

.faq__info {
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 144%;
    overflow: hidden;
    color: #5f5f5f;
    transition:
      height 0.4s ease,
      opacity 0.4s ease;
    a {
      text-decoration: underline;
      font-weight: 700;
      transition: color 0.4s ease;
      &:hover {
        color: #8a8a8a;
      }
    }
  }
}

.faq__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem 2.5rem;
  background: #f8f8f8;
  cursor: pointer;
  transition: height 0.4s ease;
  p {
    width: 85%;
  }
  svg {
    display: block;
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    transition: $anim;
    cursor: pointer;
    path {
      stroke: $dark;
    }
  }
  &:hover {
    .faq__point {
      color: #8a8a8a;
    }
    svg {
      path {
        stroke: #8a8a8a;
      }
    }
  }
}

.faq__point {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.02em;
  flex-grow: 1;
  transition: all 0.4s ease;
}

.active {
  .faq__point {
    padding-bottom: 0.75rem;
  }
  .faq__info p {
    transition:
      height 0.4s ease,
      opacity 0.4s ease;
  }
  svg {
    transform: rotate(-180deg);
  }
}

@media (max-width: 1200px) {
  .faq__point {
    font-size: 1.25rem;
  }
  .faq__item {
    padding: 1.5rem;
  }
}
@media (max-width: 1024px) {
  .faq__info {
    width: 86%;
    p {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .faq__point {
    font-size: 1.125rem;
    line-height: 28px;
  }

  .faq__item {
    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
}

@media (max-width: 576px) {
  .faq__item {
    padding: 1.25rem;
  }

  .faq__point {
    font-size: 1rem;
  }

  .faq__info p {
    font-size: 0.9375rem;
  }

  .active {
    .faq__point {
      padding-bottom: 0.5rem;
    }
  }
}

@media (max-width: 480px) {
  .faq__info {
    width: 92%;
  }
}

@media (max-width: 414px) {
  .faq__item {
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .faq__point {
    line-height: 1.25rem;
  }

  .faq__info p {
    font-size: 0.875rem;
  }
}
