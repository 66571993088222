@import '../../../../styles/vars';

.faq {
  padding: 7.5rem 0;
}

.wrapper {
  max-width: 55rem;
  margin: auto;
}

.faq__info {
  max-width: 45rem;
  margin: auto;
  padding-bottom: 3.5rem;
}

.heading {
  text-align: center;
  padding-bottom: 1rem;
  line-height: 70%;
  .word__wrapper {
    line-height: 100%;

    span {
      transform: translateY(100%);
      line-height: 110%;
      vertical-align: middle;
    }
  }

  span {
    display: inline-block;
  }
}

.excerpt {
  text-align: center;
  padding-bottom: 0;
  line-height: 73%;
  margin: auto;
  max-width: 600px;
  .word__wrapper {
    line-height: 100%;

    span {
      transform: translateY(100%);
      line-height: 140%;
      vertical-align: middle;
    }
  }

  span {
    display: inline-block;
  }
}

.word__wrapper {
  overflow-y: hidden;
  overflow-x: auto;
}

.animate__heading {
  .word__wrapper span {
    animation: $headingFade;
  }
}

.animate__excerpt {
  .word__wrapper span {
    animation: $excerptFade;
  }
}

@media (max-width: 1480px) {
  .faq {
    padding: 6.5rem 0;
  }

  .faq__info {
    padding-bottom: 3rem;
  }

  .excerpt {
    max-width: 500px;
  }
}

@media (max-width: 1200px) {
  .faq {
    padding: 5rem 0;
  }

  .heading {
    padding-bottom: 0.75rem;
  }

  .faq__info {
    padding-bottom: 2.5rem;
    max-width: 40rem;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    max-width: 100%;
    margin: 0rem 2rem;
  }
}

@media (max-width: 768px) {
  .faq {
    padding: 4rem 0;
  }

  .excerpt {
    max-width: 450px;
  }
}

@media (max-width: 576px) {
  .wrapper {
    max-width: 100%;
    margin: 0rem 1.5rem;
  }

  .faq {
    padding: 3rem 0;
  }

  .faq__info {
    padding-bottom: 2rem;
  }

  .heading {
    text-align: left;
  }

  .excerpt {
    text-align: left;
    margin: 0;
  }
}

@media (max-width: 480px) {
  .faq__info {
    padding-bottom: 1.5rem;
  }
}
