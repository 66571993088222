.faq__wrapper {
  max-width: 55rem;
  margin: 0 auto;
}

.faq__list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (max-width: 1200px) {
  .faq__wrapper {
    max-width: 100%;
    width: auto;
  }
}
