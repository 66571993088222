@import '../../../../styles/vars';
@import '../../../../styles/keyframes.scss';

.latest__section {
  padding: 7.5rem 0;
}

@media (max-width: 1480px) {
  .latest__section {
    padding: 6.5rem 0;
  }
}

@media (max-width: 1200px) {
  .latest__section {
    padding: 5rem 0;
  }
}

@media (max-width: 768px) {
  .latest__section {
    padding: 4rem 0;
  }
}

@media (max-width: 576px) {
  .latest__section {
    padding: 3rem 0;
  }
}
