@import '../../../../styles/vars';

.cta {
  height: 740px;
  background: $dark;
  position: relative;
  overflow: hidden;
  display: flex;
}

.cta__left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 120px;
  max-width: calc(4rem + 810px + 5rem);
  padding: 0 5rem 7.5rem 4rem;
  flex-shrink: 0;
}

.heading {
  text-align: left;
  color: $white;
  padding-bottom: 0.25rem;
  line-height: 72%;
  .word__wrapper {
    line-height: 80%;
    span {
      transform: translateY(100%);
      line-height: 114%;
      vertical-align: middle;
    }
  }

  span {
    display: inline-block;
  }
}

.word__wrapper {
  overflow-y: hidden;
  overflow-x: auto;
}

.animate__heading {
  .word__wrapper span {
    animation: $headingFade;
  }
}

.animate__excerpt {
  line-height: 100%;
  .word__wrapper span {
    animation: $excerptFade;
  }
}

.excerpt {
  max-width: 600px;
  line-height: 73%;
  color: $white;
  opacity: 0.7;
  .word__wrapper {
    line-height: 100%;
    span {
      transform: translateY(100%);
      line-height: 140%;
    }
  }
  span {
    display: inline-block;
  }
}

.button {
  opacity: 0;
  animation: fadeIn 0.6s ease-in-out 1s forwards;
}

.swiper {
  position: relative !important;

  opacity: 0;
  animation: fadeIn 0.7s ease-in-out 1s forwards;
  .swiper-wrapper {
    height: calc(5000 * (27.86vw * 8 + 24px * 7));
  }
}

.background {
  width: auto;
  display: flex;
  gap: 1.5rem;
  width: auto;
  aspect-ratio: 1334 / 535;
  height: 27.86vw;
}

.background__ceil {
  position: relative;
  height: 27.86vw;
  border-radius: 4px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
  img {
    position: relative !important;
    border-radius: 4px;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
  }
}

.background__mobile {
  aspect-ratio: 262 / 535;
  border-radius: 12px;
  img {
    aspect-ratio: 262 / 535;
    border-radius: 12px;
  }
}

.background__desktop {
  aspect-ratio: 1048 / 535;
  img {
    aspect-ratio: 1048 / 535;
  }
}

@media (min-width: 1921px) {
  .background__desktop,
  .background__mobile {
    height: 535px;
  }

  .background {
    height: 535px;
    aspect-ratio: unset;
  }

  .cta__left {
    margin-left: calc((100vw - 1792px) / 2);
    padding: 0 5rem 7.5rem 0;
    max-width: calc(810px + 5rem);
  }

  .swiper {
    position: relative !important;
    top: 0;
    flex-shrink: 0;
    margin: 0;
    // max-width: calc(1792px - (810px + 5rem));
    .swiper-wrapper {
      height: calc(5000 * (27.86vw * 8 + 24px * 7));
    }
  }
}

@media (max-width: 1700px) {
  .cta__left {
    max-width: calc(2 * 4rem + 730px);
  }
}

@media (max-width: 1480px) {
  .cta {
    height: 38rem;
  }

  .cta__left {
    padding: 0 2rem 6.5rem 3rem;
    max-width: calc(2rem + 3rem + 640px);
  }

  .background {
    gap: 1rem;
  }
}

@media (max-width: 1280px) {
  .cta {
    height: 36rem;
  }

  .cta__left {
    max-width: calc(2rem + 3rem + 560px);
  }
}

@media (max-width: 1200px) {
  .cta {
    height: 34rem;
  }

  .cta__left {
    padding: 0 2rem 5rem 2.5rem;
  }
}

@media (max-width: 1024px) {
  .cta {
    height: 30rem;
  }

  .cta__left {
    max-width: 100%;
    width: 55%;
    padding: 0 2rem 5rem 2rem;
  }

  .background__ceil,
  .background {
    height: 24vw;
  }

  .swiper {
    position: relative !important;
    top: 0;
    flex-shrink: 0;
    margin: 0;
    .swiper-wrapper {
      height: calc(5000 * (24vw * 8 + 24px * 7));
    }
  }
}

@media (max-width: 820px) {
  .cta {
    height: auto;
    flex-direction: column;
    padding-bottom: 3rem;
  }

  .cta__left {
    width: 79%;
    justify-content: flex-start;
    height: auto;
    padding-top: 5rem;
    padding-bottom: 3rem;
  }

  .background {
    display: flex;
    gap: 1rem;
    width: auto;
    height: auto;
    aspect-ratio: unset;
  }

  .background__ceil {
    width: fit-content;
    height: auto;
  }

  .swiper {
    .swiper-wrapper {
      height: auto !important;
      width: auto;
    }
  }

  .background__desktop {
    width: 560px;
    height: auto;
  }

  .background__mobile {
    width: 140px;
    height: auto;
  }

  .second__swiper {
    display: none;
  }
}

@media (max-width: 768px) {
  .background__desktop {
    width: 480px;
  }

  .background__mobile {
    width: 120px;
  }
}

@media (max-width: 640px) {
  .cta__left {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .cta__left {
    padding: 5rem 1.5rem 3rem;
    height: auto;
  }

  .background__desktop {
    width: 400px;
  }

  .background__mobile {
    width: 100px;
  }

  .section__container {
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 480px) {
  .cta {
    padding-bottom: 2rem;
  }

  .cta__left {
    padding: 4rem 1.5rem 2rem;
    height: auto;
  }

  .button {
    width: 100%;
  }

  .heading {
    padding-bottom: 0.5rem;
  }
}

@media (max-width: 414px) {
  .cta {
    padding-bottom: 1.5rem;
  }

  .background__desktop {
    width: 360px;
  }

  .background__mobile {
    width: 90px;
    border-radius: 4px;
    img {
      border-radius: 4px;
    }
  }

  .cta__left {
    padding: 4rem 1.25rem 1.5rem;
  }
}

@media (max-width: 376px) {
  .background__desktop {
    width: 300px;
  }

  .background__mobile {
    width: 75px;
  }
}
